.slider-container {
    position: relative;
    height: 100%;
    user-select: none;
    overflow: hidden;
 }

 /*.slider-container:active{
    cursor: grabbing;
    cursor: -moz-grabbing;
    cursor: -webkit-grabbing;
 }*/

  .slider-control {
    z-index: 2;
    position: absolute;
    top: 0;
    width: 12%;
    height: 100%;
    transition: opacity 0.3s;
    will-change: opacity;
    opacity: 0;
    cursor: pointer;
 }

  .slider-control:not(.inactive):hover {
    opacity: 1;
 }
  .slider-control.left {
    left: 0;
    background: linear-gradient(to right, rgba(0, 0, 0, 0.18) 0%, rgba(0, 0, 0, 0) 100%);
 }
  .slider-control.right {
    right: 0;
    background: linear-gradient(to right, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.18) 100%);
 }
  .slider-pagi {
    position: absolute;
    z-index: 3;
    left: 50%;
    bottom: 2rem;
    transform: translateX(-50%);
    font-size: 0;
    list-style-type: none;
 }
  .slider-pagi__elem {
    position: relative;
    display: inline-block;
    vertical-align: top;
    width: 10px;
    height: 10px;
    margin: 0 0.5rem;
    border-radius: 50%;
    border: 1px solid #fff;
    cursor: pointer;
    margin-top: 10px;
 }
  .slider-pagi__elem:before {
    content: "";
    position: absolute;
    left: 50%;
    top: 50%;
    width: .6rem;
    height: .6rem;
    background: #fff;
    border-radius: 50%;
    transition: transform 0.3s;
    transform: translate(-50%, -50%) scale(0);
 }
  .slider-pagi__elem.active:before, .slider-pagi__elem:hover:before {
    transform: translate(-50%, -50%) scale(1);
 }
  .slider {
    z-index: 1;
    position: relative;
    height: 100%;
 }
  .slider.animating {
    transition: transform 0.5s;
    will-change: transform;
 }
  .slider.animating .slide__bg {
    transition: transform 0.5s;
    will-change: transform;
 }
  .slide {
    position: absolute;
    top: 0;
    width: 100%;
    height: 100%;
    overflow: hidden;
 }
  .slide.active .slide__overlay, .slide.active .slide__text {
    opacity: 1;
    transform: translateX(0);
 }
  .slide__bg {
    position: absolute;
    top: 0;
    left: -50%;
    width: 100%;
    height: 100%;
    background-size: cover;
    will-change: transform;
    background-position: center;
 }
  .slide:nth-child(1) {
    left: 0;
 }
  .slide:nth-child(1) .slide__bg {
    left: 0;
    background-image: url('../img/SEINE_DESIGN-hotel-paris.jpg');
 }
  .slide:nth-child(1) .slide__overlay-path {
    fill: #e99c7e;
 }

 .slider-control.left::before{
    content: '◀';
    position: absolute;
    top: 50%;
    left: 35px;
    transform: translateY(-50%);
    font-size: 38px;
    color: #fff;
    font-family: 'Arial Black', "Arial Bold", Gadget, sans-serif;
    appearance: none;
    -moz-appearance: none;
    -webkit-appearance: none;
}

.slider-control.right::before{
    content: '▶';
    position: absolute;
    top: 50%;
    right: 35px;
    transform: translateY(-50%);
    font-size: 38px;
    color: #fff;
    font-family: 'Arial Black', "Arial Bold", Gadget, sans-serif;
    appearance: none;
    -moz-appearance: none;
    -webkit-appearance: none;
}

  .slide:nth-child(2) {
    left: 100%;
 }
  .slide:nth-child(2) .slide__bg {
    left: -50%;
    background-image: url('../img/SEINE_DESIGN-FLUCTUART_0.jpg');
    background-position: left center;
 }

  .slide:nth-child(3) {
    left: 200%;
 }
  .slide:nth-child(3) .slide__bg {
    left: -100%;
    background-image: url('../img/Seine_Design-ROSA_BONHEUR_SUR_SEINE_2_outside_view_sergio_grazia.jpg');
 }

  .slide:nth-child(4) {
    left: 300%;
 }
  .slide:nth-child(4) .slide__bg {
    left: -150%;
    background-image: url('../img/2019_-_SEINE_DESIGN_-_adamant-paris12-ECR-B-07.jpg');
 }

 .slide:nth-child(5) {
  left: 400%;
}
.slide:nth-child(5) .slide__bg {
  left: -200%;
}

.slide:nth-child(6) {
  left: 500%;
}
.slide:nth-child(6) .slide__bg {
  left: -250%;
}

.slide:nth-child(7) {
  left: 600%;
}
.slide:nth-child(7) .slide__bg {
  left: -300%;
}

.slide:nth-child(8) {
  left: 700%;
}
.slide:nth-child(8) .slide__bg {
  left: -350%;
}

.slide:nth-child(9) {
  left: 800%;
}
.slide:nth-child(9) .slide__bg {
  left: -400%;
}

.slide:nth-child(10) {
  left: 900%;
}
.slide:nth-child(10) .slide__bg {
  left: -450%;
}

.slide:nth-child(11) {
  left: 1000%;
}
.slide:nth-child(11) .slide__bg {
  left: -500%;
}

.slide:nth-child(12) {
  left: 1100%;
}
.slide:nth-child(12) .slide__bg {
  left: -550%;
}

.slide:nth-child(13) {
  left: 1200%;
}
.slide:nth-child(13) .slide__bg {
  left: -600%;
}

.slide:nth-child(14) {
  left: 1300%;
}
.slide:nth-child(14) .slide__bg {
  left: -650%;
}

.slide:nth-child(15) {
  left: 1400%;
}
.slide:nth-child(15) .slide__bg {
  left: -700%;
}

.slide:nth-child(16) {
  left: 1500%;
}
.slide:nth-child(16) .slide__bg {
  left: -750%;
}

.slide:nth-child(17) {
  left: 1600%;
}
.slide:nth-child(17) .slide__bg {
  left: -800%;
}

.slide:nth-child(18) {
  left: 1700%;
}
.slide:nth-child(18) .slide__bg {
  left: -850%;
}

.slide:nth-child(19) {
  left: 1800%;
}
.slide:nth-child(19) .slide__bg {
  left: -900%;
}

.slide:nth-child(20) {
  left: 1900%;
}
.slide:nth-child(20) .slide__bg {
  left: -950%;
}

.slide:nth-child(21) {
  left: 2000%;
}
.slide:nth-child(21) .slide__bg {
  left: -1000%;
}

.slide:nth-child(22) {
  left: 2100%;
}
.slide:nth-child(22) .slide__bg {
  left: -1050%;
}

.slide:nth-child(23) {
  left: 2200%;
}
.slide:nth-child(23) .slide__bg {
  left: -1100%;
}
.slide:nth-child(24) {
  left: 2300%;
}
.slide:nth-child(24) .slide__bg {
  left: -1150%;
}
.slide:nth-child(25) {
  left: 2400%;
}
.slide:nth-child(25) .slide__bg {
  left: -1200%;
}
.slide:nth-child(26) {
  left: 2500%;
}
.slide:nth-child(26) .slide__bg {
  left: -1250%;
}
.slide:nth-child(27) {
  left: 2600%;
}
.slide:nth-child(27) .slide__bg {
  left: -1300%;
}
.slide:nth-child(28) {
  left: 2700%;
}
.slide:nth-child(28) .slide__bg {
  left: -1350%;
}
.slide:nth-child(29) {
  left: 2800%;
}
.slide:nth-child(29) .slide__bg {
  left: -1400%;
}
.slide:nth-child(30) {
  left: 2900%;
}
.slide:nth-child(30) .slide__bg {
  left: -1450%;
}


  .slide__content {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
 }
  .slide__overlay {
    position: absolute;
    bottom: 0;
    left: 0;
    height: 100%;
    min-height: 810px;
    transition: transform 0.5s 0.5s, opacity 0.2s 0.5s;
    will-change: transform, opacity;
    transform: translate3d(-20%, 0, 0);
    opacity: 0;
 }
 /*
 @media only screen and (max-device-width: 1280px){
  .slider-pagi__elem:hover:before {
    background: transparent;
  }
  .slider-pagi__elem:active:before,
  .slider-pagi__elem:visited:before {
    background: #fff;
}
 }
 */

  @media (max-width: 991px) {
    .slide__overlay {
      display: none;
   }
 }
  .slide__overlay path {
    opacity: 0.8;
 }
  .slide__text {
    position: absolute;
    width: 25%;
    bottom: 15%;
    left: 12%;
    color: #fff;
    transition: transform 0.5s 0.8s, opacity 0.5s 0.8s;
    will-change: transform, opacity;
    transform: translateY(-50%);
    opacity: 0;
 }

  .slide__text-heading {
    font-family: 'Polar', Helvetica, Arial, sans-serif;
    font-size: 5rem;
    margin-bottom: 2rem;
 }
  .slide__text-desc {
    font-family: 'Open Sans', Helvetica, Arial, sans-serif;
    font-size: 1.8rem;
    margin-bottom: 1.5rem;
 }
  .slide__text-link {
    z-index: 5;
    display: inline-block;
    position: relative;
    padding: 0.5rem;
    font-family: 'Open Sans', Helvetica, Arial, sans-serif;
    font-size: 2.3rem;
    perspective: 1000px;
 }
  .slide__text-link:before {
    z-index: -1;
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: #000;
    transform-origin: 50% 100%;
    transform: rotateX(-85deg);
    transition: transform 0.3s;
    will-change: transform;
 }
  .slide__text-link:hover:before {
    transform: rotateX(0);
 }

 @media only screen and (max-width: 1366px){
  .slider-control.right::before,
  .slider-control.left::before{
    font-size: 32px;
  }
}

@media only screen and (max-width: 1100px){
  .slide:nth-child(2) .slide__bg{
    background-position: center;
  }
}

@media only screen and (max-width: 1024px){
  .slider-pagi__elem{
    margin-bottom: 10px;
  }

  .slider-container .slider-pagi{
    text-align: center;
    bottom: 10px;
  }

  .slider-container .slider{
    top: 0;
    left: 0;
  }
  
}

@media only screen and (max-width: 601px){
  .slider-container > .slider-control.left::before{
    left: 15px;
  }

  .slider-container > .slider-control.right::before{
    right: 15px;
  }
}
@media only screen and (max-width: 480px){
.slider-control.right::before, .slider-control.left::before {
  font-size: 26px;
}

.slider-container .slider-pagi{
  text-align: left;
  bottom: 5px;
}
}